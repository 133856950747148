@keyframes animate-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.form-login {
  display: grid !important;
  align-items: end !important;
  justify-content: center !important;
  align-content: center !important;
  min-height: 100vh;
  background-color: #00a8e4 !important;
  padding: 20px;
}

.form-login p {
  color: rgb(222 222 222);
}

.form-login p span {
  color: rgb(255, 255, 255);
  text-decoration: underline;
}

.form-login .app-title {
  font-size: 22px;
  margin: 1rem 0 !important;
}

.close-terms {
  position: absolute !important;
  position: absolute !important;
  color: rgb(16, 16, 16) !important;
  background: rgba(255, 255, 255, 0.974) !important;
  border-color: rgba(255, 255, 255, 0.137) !important;
  margin-left: 85vw !important;
  margin-right: 0% !important;
  margin-top: -70vh !important;
}

.check {
  color: white !important;
  font-size: 30px !important;
  width: 60px;
  height: 60px;
}

.keep-login .css-i4bv87-MuiSvgIcon-root {
  fill: #00a8e4 !important;
}

.check .css-i4bv87-MuiSvgIcon-root {
  width: 1.5em;
  height: 1.5em;
}

.css-ahj2mt-MuiTypography-root {
  font-family: Figtree-Regular !important;
  line-height: 1 !important;
  animation-duration: 1s;
  animation-name: animate-fade;
  animation-delay: 1.5s;
  animation-fill-mode: backwards;
}

.form-login p,
label,
a {
  animation-duration: 1s;
  animation-name: animate-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

.form-login .password-field input,
.form-login .phone-number input {
  height: 15px;
  align-self: stretch;
  color: #fff;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 16px 24px;
  border-radius: 8px;
  border: solid 1px #ffffff;
  background-color: rgba(128, 102, 236, 0.1);
}

/* <--- password input ---> */
.css-e363es-MuiFormControl-root,
.css-h6iiar {
  animation-duration: 1s;
  animation-name: animate-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
  width: 100% !important;
  margin: 8px 0px !important;
}

.btn-secondary {
  animation-duration: 1s;
  animation-name: animate-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

.Mui-focused {
  color: white !important;
  border-color: #ffffff !important;
  border-width: 1px;
}

.link-org .MuiSelect-select,
.link-org .Mui-focused {
  color: rgb(0, 0, 0) !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #ffffff !important;
  border-width: 1px;
}

/* .link-org .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgb(40, 40, 40) !important;
    border-width: 2px;
} */

.login-btn {
  width: 100%;
  background-color: #00a8e4 !important;
  color: rgb(255, 255, 255) !important;
  padding: 10px !important;
  animation-duration: 1s;
  animation-name: animate-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

.select-control {
  width: 325px !important;
  padding: 0px !important;
  animation-duration: 1s;
  animation-name: animate-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
  color: white !important;
}

.register-btn {
  max-width: 240px;
  width: 100%;
  border-radius: 25px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  color: rgb(255, 255, 255) !important;
  border: none;
  box-shadow: none !important;
  animation-duration: 1s;
  animation-name: animate-fade;
  animation-delay: 1.5s;
  animation-fill-mode: backwards;
}

.back-btn {
  position: absolute !important;
  border-radius: 25px !important;
  background-color: rgba(120, 0, 0, 0) !important;
  color: rgb(255, 255, 255) !important;
  border: none !important;
  padding: 10px !important;
  box-shadow: none !important;
  animation-duration: 1s;
  animation-name: animate-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
  z-index: 1;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  animation-duration: 1s;
  animation-name: animate-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
  color: white !important;
  width: 100% !important;
}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
  font-weight: 200 !important;
}

.form-login label {
  color: white !important;
}

label[data-shrink="false"]+.MuiInputBase-formControl .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  height: 15px;
}

.modal-settings {
  display: flex;
  margin-top: 1rem;
  width: 100%;
  position: sticky;
  justify-content: center;

  & div {
    width: 50%;
  }
}

@media only screen and (max-width: 430px) {
  .logo {
    width: 100%;
  }
}

.button-inquire-hide {
  color: black !important;
  margin-top: 40px !important;
}

.logo {
  display: flex;
  height: 100%;
  position: absolute;
  width: 100%;
  animation-duration: 1s;
  animation-name: animate-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

h3 {
  text-align: center;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  color: white !important;
  width: 100% !important;
}

.link-org .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  color: rgb(0, 0, 0) !important;
}

.password .css-i4bv87-MuiSvgIcon-root {
  fill: white !important;
  width: 100% !important;
}

.step-container {
  height: 100vh;
}

.alert {
  margin: 10% 0px;
  position: absolute !important;
  margin-left: 5%;
  width: 90%;
  z-index: 10000;
  animation-duration: 1s;
  animation-name: animate-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

.form-logo {
  animation-duration: 1s;
  animation-name: animate-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

.confirm-pin {
  display: flex;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #46555a;
}

@media only screen and (min-width: 1200px) {
  .alert {
    margin: 10% 0px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .terms {
    margin-top: 5% !important;
  }
}

@media only screen and (min-width: 720px) {
  .alert {
    margin: 20px -10px 40px -5px;
    width: 30%;
    text-align: left;
    justify-content: left;
    position: relative;
    left: calc(100% - 30%);
  }

  .terms {
    margin-top: 5% !important;
  }
}

@font-face {
  font-family: "Figtree-Regular";
  src: local("Figtree-Regular"),
    url(../fonts/Figtree-Regular.ttf) format("truetype");
}
