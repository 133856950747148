.signup-form-header {
    height: 255px;
    max-width: 400px;
    width: 100%;
    padding: 2;
}

.logo-container {
    align-items: center;
    justify-content: center;
    display: flex;
}

.logo-container .form-logo {
    position: relative;
    max-width: 125px;
    z-index: 11;
}

.signup-form-header .header-desc {
    text-align: center;
    color: #fff;
}

.signup-form-header .terms-cond-label {
    color: #fff;
    /* font-size: 12px !important; */
}

.signup-form-header .terms-cond-label span {
    text-decoration: underline;
}

.form-signup {
    display: grid !important;
    align-items: stretch;
    justify-items: center !important;
    justify-content: center !important;
    align-content: stretch;
    min-height: 100vh;
    height: 100%;
    width: 50%;
    background: #0054a6;
    padding: 20px;
    position: absolute;
}

.form-signup label {
    color: white !important;
}


.form-signup .css-1jabshk {
    height: 100% !important;
    max-width: 100%;
    width: 100%;
    padding: 0px 0px;
}

.form-signup .css-zjf1nq-MuiPaper-root {
    display: none !important;
}

.form-signup .css-1x1xx5k {
    height: 100%;
    width: 100%;
    max-width: 100%;
}

.form-signup .css-10asl6r {
    margin-top: -60px;
}

.form-signup .css-kzv9dm {
    width: 100%;
}

.form-signup .title {
    font-size: 25px;
    font-weight: 500;
    margin: 20px 0px 0px 0px;
    padding-top: 10px;
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
}

/* .form-signup p {
    font-size: 18px;
    font-weight: 500;
    margin: 20px 0px;
    color: white;
    line-height: 1em;
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
} */

.form-signup .css-r47a1p-MuiFormControl-root {
    width: 100% !important;
}

.form-signup .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    width: 100% !important;
}

.form-signup .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    width: 100% !important;
}


.form-signup .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    width: 100% !important;
}

.form-signup .css-rh92k-MuiPaper-root-MuiMobileStepper-root {
    margin-top: -60px;
}

.form-signup .chips {
    color: #ffffffbf;
    margin: 3px;
    border-width: 2px;
}

.form-signup .MuiFormControl-root input,
.form-signup .phone-number input {
    height: 15px;
    align-self: stretch;
    color: #fff;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 16px 24px;
    border-radius: 8px;
    border: solid 1px #ffffff;
    background-color: rgba(0, 0, 0, 0.086);
}

@media screen and (max-width: 768px) {
    .form-signup {
        min-height: 152vh;
        width: 100%;
    }
    
}

.css-i44wyl {
    width: 100% !important;
}